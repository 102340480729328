export const mediaTypeLabels = {
    'Digital Display': 'Digital - Display',
    'Digital Search': 'Digital - Search',
    'Digital Social': 'Digital - Social',
    'Digital Video': 'Digital - Video',
    'Regional Radio': 'Radio - Regional',
    Digital: 'Digital - Display',
    Radio: 'Radio - Metro',
    STV: 'Subscription TV',
    'Regional TV': 'Free-to-air TV - Regional',
    Television: 'Free-to-air TV - Metro',
    'Out of Home': 'Out of Home',
    Newspaper: 'Newspaper',
    Magazine: 'Magazine',
    Classified: 'Classified'
};
