export type AdTypeInput = {name: string; id: string; canonicalName: string};

export type CanonicalAdTypeName =
    | 'Brand'
    | 'Retail'
    | 'Sponsorship'
    | 'Uncategorised'
    | 'Competition';
export default class AdType {
    private data: AdTypeInput;
    type = 'adType' as const;

    constructor(input: AdTypeInput) {
        this.data = input;
    }

    get id() {
        return this.data.id || this.data.name;
    }

    get canonicalName() {
        switch (this.data.canonicalName) {
            case 'Brand':
            case 'Retail':
            case 'Sponsorship':
            case 'Uncategorised':
            case 'Competition':
                return this.data.canonicalName;
            default:
                break;
        }
    }

    get label() {
        return this.data.name;
    }

    get labelV2() {
        if (this.canonicalName === 'Sponsorship' || this.canonicalName === 'Competition')
            return `${this.label} (Discontinued)`;
        return this.label;
    }
}
